import * as Types from '../../types';

import { gql } from '@apollo/client';
import { GateWithLastOpenFragmentDoc } from './GatesQuery.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GateOpenSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type GateOpenSubscription = { __typename?: 'subscription_root', gate_open: Array<{ __typename?: 'gate_open', gate: { __typename?: 'gate', id: string, name: string, number: number | null, delay: number, cctv_preview_rate: number, opens: Array<{ __typename?: 'gate_open', created_at: import("dayjs").Dayjs }>, cctv: { __typename?: 'cctv', url: string | null, preview: string | null, is_enabled: boolean | null } | null } }> };


export const GateOpenSubscriptionDocument = gql`
    subscription GateOpenSubscription {
  gate_open(order_by: {created_at: desc}, limit: 1) {
    gate {
      ...GateWithLastOpen
    }
  }
}
    ${GateWithLastOpenFragmentDoc}`;

/**
 * __useGateOpenSubscription__
 *
 * To run a query within a React component, call `useGateOpenSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGateOpenSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGateOpenSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGateOpenSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GateOpenSubscription, GateOpenSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GateOpenSubscription, GateOpenSubscriptionVariables>(GateOpenSubscriptionDocument, options);
      }
export type GateOpenSubscriptionHookResult = ReturnType<typeof useGateOpenSubscription>;
export type GateOpenSubscriptionSubscriptionResult = Apollo.SubscriptionResult<GateOpenSubscription>;